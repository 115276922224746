<template>
  <div class="or">
    <van-steps direction="vertical" :active="active" active-color="#0065FF">
      <van-step>
        <h3>麦当劳-张三丰 <span>通过延期申请</span></h3>
        <div class="time">
          <p class="time_p">20:40</p>
          <p class="date_p">21-03-23</p>
        </div>
      </van-step>
      <van-step>
        <h3>麦当劳-张三丰 <span>拒绝延期申请</span></h3>
        <div class="reject_font">拒绝原因：拒绝原因说明文字</div>
        <div class="time">
          <p class="time_p">19:42</p>
          <p class="date_p">21-03-23</p>
        </div>
      </van-step>
      <van-step>
        <h3>麦当劳-刘海宽 <span>提交延期申请</span></h3>
        <div class="time">
          <p class="time_p">18:48</p>
          <p class="date_p">21-03-23</p>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const active = ref(0);
    return { active };
  }
};
</script>

<style lang="less" scoped>
.or {
  padding-top: 15px;
  /deep/ .van-step__title h3 {
    margin: 0;
    font-size: 15px;
    color: #0065ff;
    line-height: 24px;
    span {
      font-size: 15px;
      color: #333333;
      line-height: 24px;
    }
  }
  /deep/ .van-steps--vertical {
    padding-left: 82px;
  }
  /deep/ .van-step--vertical {
    padding-bottom: 38px;
  }
  .reject_font {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #5f6673;
    letter-spacing: 0;
    line-height: 24px;
  }
  .time {
    position: absolute;
    left: -71px;
    top: 10px;
    p {
      margin: 0;
    }
    .time_p {
      font-size: 18px;
      color: #333333;
      text-align: right;
      line-height: 24px;
    }
    .date_p {
      font-size: 12px;
      color: #959fac;
      text-align: right;
      line-height: 15px;
    }
  }
}
</style>
